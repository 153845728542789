import { Component, OnInit, ElementRef, Renderer2, ViewChild, Input } from '@angular/core';
import { NgIf } from '@angular/common';

import { AuthenticatedUserService } from '@services/authenticated-user/authenticated-user.service';
import { BankingDetailsService } from '@services/banking-details/banking-details.service';
import { WorkerBankingDetails } from '@entities/workers/worker-banking-details.entity';
import { PaidiemLogoComponent } from '@components/paidiem-logo/paidiem-logo.component';
import { PageStateService } from '@services/page-state/page-state.service';

import { PoliciesModalComponent } from '@components/policies-modal/policies-modal.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ToastService } from '@services/toast/toast.service';
import { OverlayLoaderComponent } from '@core/components/overlay-loader/overlay-loader.component';

@Component({
  selector: 'pd-tabapay-embedded-iframe',
  standalone: true,
  imports: [NgIf, PaidiemLogoComponent, PoliciesModalComponent, IconComponent, OverlayLoaderComponent],
  templateUrl: './tabapay-embedded-iframe.component.html',
})
export class TabapayEmbeddedIframeComponent implements OnInit {
  @ViewChild('tabapayIframe', { static: true })
  private tabapayIframeContainer: ElementRef;
  private tabapaySdkScript: HTMLScriptElement;

  @Input() public onDebitCardDetailsCreation: () => void;
  public invalidCardError: boolean = false;

  public constructor(
    private renderer: Renderer2,
    private bankingDetailsService: BankingDetailsService,
    private authenticatedUserService: AuthenticatedUserService,
    public pageState: PageStateService,
    private toastService: ToastService,
    private authenticationService: AuthenticationService
  ) {}

  public ngOnInit(): void {
    this.initializeTabapayScript();

    this.tabapaySdkScript.onload = () => {
      this.initializeTabapayIframe();
    };
  }

  private initializeTabapayScript(): void {
    this.tabapaySdkScript = document.createElement('script');
    this.tabapaySdkScript.type = 'text/javascript';
    this.tabapaySdkScript.src = 'https://iframes.sandbox.tabapay.net/TabaPaySDK.js';
    document.head.appendChild(this.tabapaySdkScript);
  }

  private initializeTabapayIframe() {
    const config = this.getTabapayIframeConfig();
    const { element } = (window as any).tabaPaySdk.createIframe(config);

    if (this.tabapayIframeContainer) {
      this.renderer.appendChild(this.tabapayIframeContainer.nativeElement, element);
    }
  }

  private getTabapayIframeConfig() {
    return {
      cardNumberInput: {
        cardLogo: 'inside',
        separator: '',
        placeholderText: '13-19 digits',
        invalidText: 'Please enter your Card Number',
        invalidTextPosition: 'top-right',
        invalidLabelColorEvent: 'change',
      },
      expirationDateInput: {
        labelText: 'Card Expiration Date',
        placeholderText: 'MM/YY format',
        invalidText: 'Please enter your Card Expiration Date',
        invalidTextPosition: 'top-right',
        invalidLabelColorEvent: 'change',
      },
      cscInput: {
        labelText: 'Card Security Code',
        placeholderText: 'Optional 3-4 digits',
        required: false,
        invalidText: 'Please enter your Card Security Code',
        invalidTextPosition: 'top-right',
        invalidLabelColorEvent: 'change',
      },
      buttons: {
        placement: 'space-evenly',
        submit: {
          backgroundColorEnabled: '#7448FF',
          backgroundColorDisabled: '#7448FF',
          backgroundColorHover: '#36b853',
          textColorEnabled: '#fff',
          border: '2px solid #3A2480',
          borderRadius: '10px',
          margin: '30px 0 0 0',
          buttonText: 'Use Card',
          borderColorHover: '2px solid #3A2480',
          padding: '10px 2px',
          textColorHover: '#3A2480',
          textColorDisabled: '#fff',
          width: '25%',
          height: '42px',
          order: '3',
        },
        reset: {
          backgroundColorEnabled: '#7448FF',
          backgroundColorHover: '#36b853',
          textColorEnabled: '#fff',
          border: '2px solid #3A2480',
          borderRadius: '10px',
          margin: '30px 0 0 0',
          buttonText: 'Clear',
          backgroundColorDisabled: '#7448FF',
          textColorHover: '#3A2480',
          textColorDisabled: '#fff',
          borderColorHover: '2px solid #3A2480',
          padding: '10px 18px',
          width: '25%',
          height: '42px',
          order: '2',
        },
      },
      labelStyle: {
        fontWeight: 'bold',
        fontFamily: 'Arial',
        gap: '0em',
      },
      inputStyle: {
        padding: '10px',
        fontSize: '1em',
        fontFamily: 'Arial',
        borderRadius: '1px',
        borderStyle: '1px solid',
        invalidBackgroundColorFocus: '#fee',
        invalidBackgroundColor: '#fee',
        boxShadowFocus: '0 0 10px #ca3',
        borderFocus: '2px solid',
        borderColorFocus: '#ca3',
        invalidBoxShadowFocus: '0 0 10px #ca3',
        invalidBorder: '2px solid ',
        invalidBorderColor: '#e55',
        invalidBorderFocus: '2px solid',
        invalidBorderColorFocus: '#e55',
      },
      invalidStyle: {
        textColor: '#e55',
        fontSize: '0.7em',
        fontWeight: '400',
      },
      insetFrame: {
        backgroundColor: '#fff',
        maxWidth: '100%',
      },
      unlimitedWidth: true,
      formHorizontalGap: '1.5em',
      formRowGap: '0.5em',
      layout: 'separate-rows',
      clientId: 'jjEIyAAAgauKe3ZuN2JX7A',
      eventListeners: {
        submit: (encryptedCardData: any) => {
          const createWorkerDebitCardDetails$ = this.bankingDetailsService.createWorkerDebitCardDetails(
            this.authenticatedUserService.authenticatedUser.id,
            encryptedCardData.split('|')[2]
          );
          this.pageState.setSavingObservable(createWorkerDebitCardDetails$);
          createWorkerDebitCardDetails$.subscribe({
            next: (createdWorkerBankingDetails: WorkerBankingDetails) => {
              if (createdWorkerBankingDetails) {
                this.onDebitCardDetailsCreation();
              }
            },
            error: (res) => {
              this.toastService.info(`${res.error?.code}`);
              this.invalidCardError = true;
            },
          });
        },
      },
    };
  }

  public logout() {
    const logoutSession$ = this.authenticationService.logoutSession();
    logoutSession$.subscribe({
      next: () => {
        this.authenticationService.embeddedLogout(this.authenticatedUserService.authenticatedUser.id);
      },
      error: () => {
        this.toastService.errorOccurred();
      },
    });
  }
}
