import { Injectable } from '@angular/core';
import { Router, UrlTree, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@services/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class EmbeddedAuthenticationGuard {
  public constructor(private authService: AuthenticationService, private router: Router) {}

  public canMatch(
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      const lastSegment = segments[segments.length - 1];
      if (segments && Number(lastSegment.path)) {
        localStorage.setItem('paidiem_uid', lastSegment.path);
      }
      return this.router.parseUrl(`/embedded/embedlogin/${lastSegment.path}`);
    }
  }
}
