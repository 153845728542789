import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthenticationGuard } from '@core/guards/authentication.guard';
import { LoginGuard } from '@core/guards/login.guard';
import { RoleGuard } from '@core/guards/role.guard';
import { UserRole } from '@entities/users/user-role.enum';
import { BreadcrumbRouteToken } from '@services/breadcrumbs/breadcrumb.service';
import { DailyPayApproverRedirectGuard } from '@core/guards/daily-pay-approver-redirect.guard';
import { AppAuthenticatedLayoutComponent } from './app-authenticated-layout.component';
import { AppAuthenticatedEmbeddedLayoutComponent } from './app-authenticated-embedded-layout.component';
import { EmbeddedAuthOtpComponent } from '@modules/dashboard/embedded-per-diem-dash/embedded-auth-otp.component';
import { EmbeddedAuthenticationGuard } from '@core/guards/embedded-authentication.guard';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: '', redirectTo: 'login', pathMatch: 'full' },
        {
          path: '',
          loadChildren: () =>
            import('./modules/authentication/authentication-routing.module').then((m) => m.AuthenticationRoutingModule),
          title: 'Log In',
          canActivate: [LoginGuard],
        },
        {
          path: '',
          component: AppAuthenticatedLayoutComponent,
          children: [
            {
              path: 'clients',
              data: { breadcrumb: 'Clients', analytics: { category: 'Clients' } },
              loadChildren: () =>
                import('./modules/clients/clients-routing.module').then((m) => m.ClientsRoutingModule),
              canMatch: [AuthenticationGuard],
              canActivate: [RoleGuard.forRoles(UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager)],
            },
            {
              path: 'dashboard',
              loadComponent: () => import('./modules/dashboard/dashboard.component').then((m) => m.DashboardComponent),
              title: 'Dashboard',
              data: {
                breadcrumb: { label: BreadcrumbRouteToken.WelcomeWorkerName, isDynamic: true, truncate: 50 },
                analytics: { category: 'Dashboard' },
              },
              canMatch: [AuthenticationGuard],
              canActivate: [RoleGuard.forRoles(UserRole.Worker)],
            },
            {
              path: 'companies',
              data: { breadcrumb: 'Companies', analytics: { category: 'Companies' } },
              loadChildren: () =>
                import('@modules/companies/companies-routing.module').then((m) => m.CompaniesRoutingModule),
              canMatch: [AuthenticationGuard],
              canActivate: [RoleGuard.forRoles(UserRole.SuperAdmin, UserRole.Admin)],
            },
            {
              path: 'workers',
              data: { breadcrumb: 'Workers', analytics: { category: 'Workers' } },
              loadChildren: () =>
                import('./modules/workers/workers-routing.module').then((m) => m.WorkersRoutingModule),
              canMatch: [AuthenticationGuard],
              canActivate: [
                RoleGuard.forRoles(UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager, UserRole.Approver),
              ],
            },
            {
              path: 'users',
              data: { breadcrumb: 'Users', analytics: { category: 'Users' } },
              loadChildren: () => import('./modules/users/users-routing.module').then((m) => m.UsersRoutingModule),
              canMatch: [AuthenticationGuard],
              canActivate: [RoleGuard.forRoles(UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager)],
            },
            {
              path: 'timesheets',
              data: { breadcrumb: 'Timesheets', analytics: { category: 'Timesheets' } },
              loadChildren: () =>
                import('./modules/timesheets/timesheets-routing.module').then((m) => m.TimesheetsRoutingModule),
              canMatch: [AuthenticationGuard],
            },
            {
              path: 'expenses',
              data: { breadcrumb: 'Expenses', analytics: { category: 'Expenses' } },
              loadChildren: () =>
                import('./modules/expenses/expenses-routing.module').then((m) => m.ExpensesRoutingModule),
              canMatch: [AuthenticationGuard],
            },
            {
              path: 'reports',
              data: { breadcrumb: 'Reports', analytics: { category: 'Reports' } },
              loadChildren: () =>
                import('./modules/reports/reports-routing.module').then((m) => m.ReportsRoutingModule),
              canMatch: [AuthenticationGuard],
              canActivate: [RoleGuard.forRoles(UserRole.SuperAdmin, UserRole.Admin, UserRole.Manager, UserRole.Worker)],
            },
            {
              path: 'settings',
              data: { breadcrumb: 'Settings', analytics: { category: 'Settings' } },
              loadChildren: () =>
                import('./modules/settings/settings-routing.module').then((m) => m.SettingsRoutingModule),
              canMatch: [AuthenticationGuard],
            },
            {
              path: 'advance-payments',
              data: { breadcrumb: 'Advance Payments', analytics: { category: 'Advance Payments' } },
              loadChildren: () =>
                import('./modules/advance-payments/advance-payments-routing.module').then(
                  (m) => m.AdvancePaymentsRoutingModule
                ),
              canMatch: [AuthenticationGuard],
              canActivate: [RoleGuard.forRoles(UserRole.SuperAdmin, UserRole.Admin)],
            },
            {
              path: 'admin-tools/hr-employee-import',
              data: {
                breadcrumb: 'Employee Import',
                analytics: {
                  category: 'Employee Import',
                },
              },
              loadComponent: () =>
                import('./modules/admin-tools/hr-employee-import/hr-employee-import.component').then(
                  (m) => m.HrEmployeeImportComponent
                ),
            },
            {
              path: 'payment-transactions',
              data: { breadcrumb: 'Payment Transactions', analytics: { category: 'Payment Transactions' } },
              loadComponent: () =>
                import('./modules/payment-transactions/payment-transactions.component').then(
                  (m) => m.PaymentTransactionsComponent
                ),
              canMatch: [AuthenticationGuard],
              canActivate: [RoleGuard.forRoles(UserRole.SuperAdmin, UserRole.Admin)],
            },
            {
              path: 'admin-tools',
              data: { breadcrumb: 'Admin Tools', analytics: { category: 'Admin Tools' } },
              loadComponent: () =>
                import('./modules/admin-tools/admin-tools.component').then((m) => m.AdminToolsComponent),
              canMatch: [AuthenticationGuard],
              canActivate: [RoleGuard.forRoles(UserRole.Admin, UserRole.SuperAdmin)],
            },
            {
              path: 'payroll',
              data: { breadcrumb: false, analytics: { category: 'Payroll' } },
              loadComponent: () => import('./modules/payroll/payroll.component').then((m) => m.PayrollComponent),
              children: [
                {
                  path: 'pay-statements',
                  data: { breadcrumb: 'Payroll' },
                  loadComponent: () =>
                    import('./modules/payroll/pay-statements-listing/pay-statements-listing.component').then(
                      (m) => m.PayStatementsListingComponent
                    ),
                },
              ],
              canMatch: [AuthenticationGuard],
              canActivate: [RoleGuard.forRoles(UserRole.Admin, UserRole.SuperAdmin, UserRole.Worker)],
            },
            {
              path: 'support',
              data: { breadcrumb: 'Support', analytics: { category: 'Support' } },
              loadComponent: () => import('./modules/support/support.component').then((m) => m.SupportComponent),
              canMatch: [AuthenticationGuard],
            },
            {
              path: 'paymentRequest',
              canActivate: [DailyPayApproverRedirectGuard],
              children: [],
            },
          ],
        },
        {
          path: 'embedded',
          component: AppAuthenticatedEmbeddedLayoutComponent,
          children: [
            {
              path: 'embedlogin',
              component: EmbeddedAuthOtpComponent,
              title: 'MFP Login',
              data: {
                analytics: { category: 'EmbeddedLogin' },
              },
            },
            {
              path: 'embedlogin/:id',
              component: EmbeddedAuthOtpComponent,
              title: 'MFP Login',
              data: {
                analytics: { category: 'EmbeddedLogin' },
              },
            },
            {
              path: 'embeddash/:id',
              loadComponent: () =>
                import('./modules/dashboard/embedded-per-diem-dash/embedded-per-diem-dash.component').then(
                  (m) => m.EmbeddedPerDiemDashComponent
                ),
              title: 'Dashboard',
              data: {
                breadcrumb: { label: BreadcrumbRouteToken.WelcomeWorkerName, isDynamic: true, truncate: 50 },
                analytics: { category: 'EmbeddedDashboard' },
              },
              canMatch: [EmbeddedAuthenticationGuard],
              canActivate: [RoleGuard.forRoles(UserRole.Worker)],
            },
          ],
        },
        {
          path: 'daily-pay-approver/payment-request',
          loadComponent: () =>
            import('./modules/daily-pay-approver/daily-pay-approver.component').then(
              (m) => m.DailyPayApproverComponent
            ),
        },
        { path: '**', redirectTo: 'login', pathMatch: 'full' },
      ],
      {
        bindToComponentInputs: true,
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always',
        scrollPositionRestoration: 'enabled',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
